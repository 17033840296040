import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActiveStep, usePeriod, useSetCalibers } from "../../self-assessment-form-context";
import { AgreeRow } from "../agree-row";
import { AttachmentsSection } from "../attachments";
import {
  RE_SUBMIT_STATUS,
  RE_SUBMIT_STATUS_API_VALUE,
} from "../../../../../../common/constants/assessmentStatuses";
import {
  CheckIcon,
  PrevAnswersWithHistory,
} from "../../../../../../components/establishmentprofile/PrevAnswers/index";
import { Stack } from "@mui/material";
import { TYPE_QUALITATIVE_ID } from "../../../../../../common/constants/caliberTypes";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";
import "./term-section.css";
import {
  ANSWER_NO,
  ANSWER_NOT_APPLICABLE, ANSWER_YES,
} from "../../../../../../common/constants/caliberAnswers";
import { NonComplianceRow } from "../non-compliance-row";
import { ViolatorsRow } from "../violators-row/violators-row";
import {sendCaliberAnswer} from "../agree-row/helper";

const boldFont = { fontWeight: "bold", fontSize: 16, color: "#0E2A37" };

const hashNumbers = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
  11: "11",
  12: "12",
};

function fixAndSplitString(inputString) {
  const strArr = [];
  inputString
    .replace("-", "")
    .replace("- .", "")
    .replace("-.", "")
    .split("\n")
    .forEach((str) => {
      if (
        (str?.length > 2 && str !== "\n" && !hashNumbers[str]) ||
        str !== "-" ||
        !Number(str)
      ) {
        strArr.push(str);
      }
    });

  return strArr;
}

export const TermSection = ({
                              data = {},
                              step,
                              refreshCaliber,
                              errors,
                              setErrors,
                            }) => {
  const {
    description,
    corrections,
    answer,
    new_answer,
    attachments,
    new_attachments,
    is_answered,
    integrationServiceValue,
    is_returned,
  } = data ?? {};
  const { categoryIndex, caliberIndex } = useActiveStep();
  const caliberIsQualitative = data.caliber_type.id === TYPE_QUALITATIVE_ID;
  const answerValue = data?.is_returned ? data?.new_answer : data?.answer;

  const period = usePeriod();
  const status = period.assessment?.status?.split(" ")?.join("");
  const isReturned =
    period?.audit_status === RE_SUBMIT_STATUS ||
    period?.audit_status === RE_SUBMIT_STATUS_API_VALUE;

  const setCalibers = useSetCalibers();
  const establishmentProfileId = useSelector(
    (state) => state?.establishment.establishmentProfile.id
  );

  useEffect(() => {
    if (data.is_disclaimer_caliber && data?.answer != ANSWER_YES) {
      sendCaliberAnswer(ANSWER_YES, data,  step?.caliber_id, setCalibers, establishmentProfileId, period, isReturned, status);
    }
  }, [data.is_disclaimer_caliber]);

  return (
    <div className="">
      {!data?.is_disclaimer_caliber && (
        <>
          <div className="term-section-caliber-name">
            <Text
              Tag={"div"}
              textType="roman"
              fontSize={"font-20"}
              mobileFontSize={"font-16-mobile"}
              style={{ color: "#000" }}
              className="mb-0"
            >
              {"البند"} {categoryIndex + 1}.{caliberIndex + 1}
            </Text>
          </div>

          <div className="mt-9 flex flex-col">
            <Text
              Tag={"div"}
              textType="bold"
              fontSize={"font-18"}
              mobileFontSize={"font-16-mobile"}
              style={{ color: "#14415A" }}
            >
              {"شرح البند"}
            </Text>
            {fixAndSplitString(description)?.map((str, index) => (
              <Text
                key={index}
                Tag={"div"}
                textType="roman"
                fontSize={"font-17"}
                mobileFontSize={"font-15-mobile"}
                style={{ color: "#000", textAlign: "justify" }}
                className="mb-0 leading-8"
              >
                {str}
              </Text>
            ))}
          </div>

          <div className="mt-9 flex flex-col">
            <Text
              Tag={"div"}
              textType="bold"
              fontSize={"font-18"}
              mobileFontSize={"font-16-mobile"}
              style={{ color: "#14415A" }}
            >
              {"المرجعية النظامية"}
            </Text>
            {fixAndSplitString(corrections)?.map((str, index) => (
              <Text
                key={index}
                Tag={"div"}
                textType="roman"
                fontSize={"font-17"}
                mobileFontSize={"font-15-mobile"}
                style={{ color: "#000", textAlign: "justify" }}
                className="mb-0 leading-8"
              >
                {str}
              </Text>
            ))}
          </div>

          <div className="w-full h-[1px] bg-gray-100 caliber-divider-line-mobile" />
          {is_returned && (
            <div className={"pointer-events-none"}>
              <PrevAnswersWithHistory {...data} />
            </div>
          )}

          {is_returned && (
            <Stack
              display={"flex"}
              direction="row"
              alignItems={"center"}
              columnGap={1}
              style={{ marginTop: "80px" }}
            >
              <span className="check-icon-size">
                <CheckIcon />
              </span>
              <Text
                Tag={"div"}
                textType="bold"
                fontSize={"font-18"}
                mobileFontSize={"font-16-mobile"}
                style={{ color: "#000" }}
              >
                {"الإجابة الحالية للمنشأة"}
              </Text>
            </Stack>
          )}

          <div id="answer">
            <AgreeRow
              isError={errors?.answer}
              step={step}
              data={data}
              answer={data?.is_returned ? data?.new_answer : data?.answer}
              caliberIsQualitative={caliberIsQualitative}
              setErrors={setErrors}
            />
          </div>

          {((answerValue === ANSWER_NOT_APPLICABLE &&
              data.is_visible_establishment_comment_not_applicable) ||
            (answerValue === ANSWER_NO &&
              data.is_visible_establishment_comment_no)) && (
            <div id="nonCompliance">
              <NonComplianceRow
                isError={errors?.nonCompliance}
                step={step}
                data={data}
                setErrors={setErrors}
                errors={errors}
              />
            </div>
          )}

          {((answerValue === ANSWER_NOT_APPLICABLE &&
              data.is_visible_establishment_violation_number_not_applicable) ||
            (answerValue === ANSWER_NO &&
              data.is_visible_establishment_violation_number_no)) && (
            <div id="violators">
              <ViolatorsRow
                isError={errors?.violators}
                step={step}
                data={data}
                setErrors={setErrors}
                errors={errors}
              />
            </div>
          )}
        </>
      )}

      {data?.is_disclaimer_caliber && (
        <>
          <div className="mt-9 flex flex-col">
               <Text
                Tag={"div"}
                textType="roman"
                fontSize={"font-17"}
                mobileFontSize={"font-15-mobile"}
                style={{ color: "#000", textAlign: "justify" }}
                className="mb-0 leading-8"
              >
                 يجب على صاحب المنشأة او المفوض عليها رفع إقرار يتضمن النص والمتطلبات التالية:
                 <br />
                 أقر بأن جميع المعايير التي تمت الإجابة عليها وما تم إرفاقه من مستندات وإثباتات هي صحيحة، وفي حال كانت خلاف الواقع أو غير حقيقية؛ فإنني أعرض نفسي للمساءلة القانونية وما يترتب عليها من آثار نظامية للمنشأة.
                 <br />
                 كما أقر بأن هذا الإقرار يتضمن العناصر التالية: المقر بما فيه، صفته، اسم المنشأة، التوقيع، رقم الهوية، رقم الوكالة (إن وجد)، السجل التجاري، وذلك وفقًا لما هو مطلوب في النموذج.

              </Text>

          </div>

        </>
      )}

      {attachments && (
        <div id="attachments">
          <AttachmentsSection
            errors={errors}
            refreshCaliber={refreshCaliber}
            step={step}
            data={data}
            attachments={is_returned ? new_attachments : attachments}
            setErrors={setErrors}
          />
        </div>
      )}
    </div>
  );
};
