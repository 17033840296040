import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import Caliber from "./Caliber/Caliber";
// import AttachmentIcon from "./Caliber/assets/attachment-icon.svg";

import "./detailed-report.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";

import ContentTable from "./ContentTable";

export const DetailedReport = ({
  standardCategories,
  assessmentType,
  isShowAuditResult,
}) => {
  const mainContentRef = useRef(null);

  // const getAttachmentsTotal = useCallback(
  //   ({ calibers = [] }) =>
  //     calibers
  //       .map(
  //         ({ attachments = [] }) =>
  //           attachments.filter((attachment) => attachment.file_name != "")
  //             .length
  //       )
  //       .reduce((a, b) => a + b, 0),
  //   []
  // );
  return (
    <div className="continer" ref={mainContentRef}>
      <section className="continer-child detailed-report">
        {standardCategories &&
          standardCategories.map((standardCategory, standardCategoryIndex) => (
            <div
              key={standardCategory.id}
              className="standard-category"
              id={`standard-category-${standardCategoryIndex}`}
            >
              <header className="standard-category-header">
                <Text
                  textType="bold"
                  fontSize="font-18"
                  className="standard-category-header__name"
                >
                  {standardCategory.name}
                </Text>
              </header>

              <main className="standard-category-main">
                {standardCategory &&
                  standardCategory.caliber_categories.map(
                    (caliberCategory, caliberCategoryIndex) => (
                      <div
                        key={caliberCategory.id}
                        className="caliber-category"
                        id={`caliber-category-${standardCategoryIndex}-${caliberCategoryIndex}`}
                      >
                        <div className="caliber-category-header">
                          <Text
                            textType="bold"
                            fontSize="font-18"
                            mobileFontSize="font-16-mobile"
                            className="caliber-category-header__name"
                          >
                            نوع المعيار: {caliberCategory.name} 
                          </Text>
                          {/* {assessmentType === ASSESSMENT_TYPE_FAST_TRACK && (
                          <div>
                            <span>{getAttachmentsTotal(caliberCategory)}</span>
                            <img
                              className="caliber-category-header__attachment-icon"
                              src={AttachmentIcon}
                            />
                          </div>
                        )} */}
                        </div>
                        {!caliberCategory.is_disclaimer_caliber && (
                          <>
                            <Text
                              textType="roman"
                              fontSize="font-20"
                              mobileFontSize="font-16-mobile"
                              className="caliber-category__title"
                            >
                              شرح المعيار:
                            </Text>
                            <Text
                              textType="roman"
                              fontSize="font-20"
                              mobileFontSize="font-16-mobile"
                              className="caliber-category__desc"
                            >
                              {caliberCategory.description}
                            </Text>
                          </>
                        )}
                        {isShowAuditResult ?
                           caliberCategory &&
                            caliberCategory.labels.map(
                              (label, caliberIndex) => (
                                  label.calibers.map((caliber, caliberIndex) => (
                                  <Caliber
                                  assessmentType={assessmentType}
                                  key={caliberIndex}
                                  caliberCategoryIndex={caliberCategoryIndex}
                                  caliberIndex={caliberIndex}
                                  standardCategoryIndex={standardCategoryIndex}
                                  caliber={caliber}
                                  isShowAuditResult={isShowAuditResult}
                                />
                                  )
                                )
                              )
                            )
                          : caliberCategory.calibers.map(
                              (caliber, caliberIndex) => (
                                <Caliber
                                  assessmentType={assessmentType}
                                  key={caliber.id}
                                  caliberCategoryIndex={caliberCategoryIndex}
                                  caliberIndex={caliberIndex}
                                  standardCategoryIndex={standardCategoryIndex}
                                  caliber={caliber}
                                  isShowAuditResult={isShowAuditResult}
                                />
                              )
                            )
                            }
                      </div>
                    )
                  )}
              </main>
            </div>
          ))}
      </section>

      <div className="continer-child content-table">
        {standardCategories && (
          <ContentTable
            standardCategories={standardCategories}
            mainContentRef={mainContentRef}
          />
        )}
      </div>
    </div>
  );
};

DetailedReport.propTypes = {
  standardCategories: PropTypes.array.isRequired,
  assessmentType: PropTypes.string.isRequired,
  isShowAuditResult: PropTypes.bool.isRequired,
};

export default DetailedReport;
