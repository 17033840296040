import React, { Component } from "react";
import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { qiwaSSOLogoutRequest } from "../../actions/authActions";
import Loader from "../Loader/Loader";
import * as auth from "../../actions/authActions";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from "reactstrap";

import "./header.css";

import HeaderLogo from "./HeaderLogo";
import HeaderNav from "./HeaderNav";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import HeaderNotification from "../../views/Components/HeaderNotification/HeaderNotification";
import { AccessiblityContainer } from "../AccessibilityContainer";

class Header extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      dropdownOpen: false,
      isLoggingOutLoader: false  // Add this line
    };
    this.toggle = this.toggle.bind(this);
    this.logOut = this.logOut.bind(this);
    this.logIn = this.logIn.bind(this);
    this.chooseEstablishment = this.chooseEstablishment.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  static sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-hidden");
  }

  static sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-minimized");
  }

  static mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("sidebar-mobile-show");
  }

  static asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle("aside-menu-hidden");
  }

  async logOut() {
    this.setState({ isLoggingOut: true });
    this.setState({ isLoggingOutLoader: true });

    try {
      if (process.env.REACT_APP_SSO_ESTABLISHMENT_AUTHORIZATION_METHOD === "hrsd") {
        window.kc.logout();
      } else {
        const result = await qiwaSSOLogoutRequest();
        if (result.error) {
          this.setState({ isLoggingOut: false });
          return;
        }
      }
    } catch (error) {
      this.setState({ isLoggingOut: false });
      toastr.error("Unexpected error during logout");
    } finally {
      this.setState({ isLoggingOutLoader: false });
    }
  }

  logIn() {
    window.location =
      process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
    window.location.reload();
  }

  chooseEstablishment(establishment) {
    this.props.actions.chooseEstablishment(establishment);
    this.props.actions.loadDurations(establishment.id);
  }

  render() {
    const { isLogin, establishments } = this.props;
    return (
      <header
        className="app-header navbar doNotPrint bg-[#FFF] dark:bg-black"
        style={{
          display: "flex",
          flex: 1,
          height: "92px",
        }}
      >
        <div className="header-logo-wrapper">
          <HeaderLogo isLogin={isLogin} />
        </div>
        <div className="header-menu-icon-wrapper">
          <HeaderNav
            isLoggedIn={isLogin}
            isExistingEstablishments={establishments.length !== 0}
          />
        </div>
        {isLogin && <Nav className="ml-auto" navbar />}

        {/* {isLogin && <HeaderNotifications history={history} />} */}
        {isLogin && (
          <div className={"flex items-center gap-x-4"}>
            <div className={"hidden xl:flex items-center gap-x-4"}>
              <AccessiblityContainer />
            </div>
            <Nav navbar style={{ order: "3" }}>
              <NavItem className="logout-btn-wrapper">
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle className="nav-link dropdown-toggle">
                    {this.state.isLoggingOutLoader ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Loader size="small" />
                      </div>
                    ) : (
                      <>
                        <span
                          className="d-md-down-none"
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          {this.props.userInfo.name_ar.String}
                        </span>
                        <img src={"img/user-profile.png"} className="img-avatar" />
                      </>
                    )}
                  </DropdownToggle>
                  <DropdownMenu
                    className={this.state.dropdownOpen ? "show" : ""}
                  >
                    <DropdownItem
                      onClick={this.logOut}
                      className="logout-dropdown-list"
                    >
                      <i className="fa fa-lock" />
                      <Text Tag="span" textType="p5">
                        تسجيل الخروج
                      </Text>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>

              </NavItem>
            </Nav>
          </div>
        )}
      </header>
    );
  }
}

Header.propTypes = {
  establishments: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  ajaxCall: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  isLogin: PropTypes.bool.isRequired,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  let userInfo = {
    id: "",
    name_ar: { String: "" },
    name_en: { String: "" },
    phone_number: { String: "" },
    email: { String: "" },
    id_number: "",
  };
  if (state.auth.userInfo) {
    userInfo = state.auth.userInfo;
  }
  return {
    ajaxCall: state.ajaxStatus,
    userInfo: userInfo,
    auth: state.auth,
    isLogin: state.auth.token !== null,
    establishments: state.establishment.establishments,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(auth, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
