import React, { useState } from "react";
import { DislikeIcon, LikeIcon } from "../../../lib/assets/icons/icons/index";
import { twMerge } from "../../../lib/tw-merge";
import { usePeriod, useSetCalibers } from "../../self-assessment-form-context";
import { useSelector } from "react-redux";
import {
  RE_SUBMIT_STATUS,
  RE_SUBMIT_STATUS_API_VALUE,
} from "../../../../../../common/constants/assessmentStatuses";
import Loader from "../../../../../../components/Loader/Loader";
import "./agree-row.css";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";
import { ANSWER_YES } from "../../../../../../common/constants/caliberAnswers";
import {sendCaliberAnswer} from "./helper";

export const AgreeRow = ({
                           answer: defaultAnswer,
                           step,
                           data,
                           isError,
                           caliberIsQualitative,
                           setErrors,
                         }) => {
  const [answer, setAnswer] = useState(defaultAnswer);
  const [isLoading, setIsLoading] = useState(false); // Initialize loading state
  const period = usePeriod();
  const status = period.assessment?.status?.split(" ")?.join("");
  const isReturned =
    period?.audit_status === RE_SUBMIT_STATUS ||
    period?.audit_status === RE_SUBMIT_STATUS_API_VALUE;

  const setCalibers = useSetCalibers();
  const establishmentProfileId = useSelector(
    (state) => state?.establishment.establishmentProfile.id
  );

  const handleSendAnswer = async (answer) => {
    setIsLoading(true); // Set isLoading to true before making the request

    try {
      const success = await sendCaliberAnswer(
        answer,
        data,
        step?.caliber_id,
        setCalibers,
        establishmentProfileId,
        period,
        isReturned,
        status
      );

      if (success) {
        setAnswer(answer);
        setErrors({});
      }
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false); // Set isLoading to false after the request is complete
    }
  };

  if (isLoading) {
    return (
      <div
        className={
          "flex-1 w-full min-h-[600px] flex items-center justify-center"
        }
      >
        <Loader />
      </div>
    );
  } else {
    return (
      <div>
        <div
          className={twMerge(
            "flex justify-between items-baseline mt-8 answer-flex-direction"
          )}
        >
          <Text
            Tag={"div"}
            textType="roman"
            fontSize={"font-18"}
            mobileFontSize={"font-16-mobile"}
            style={{ color: "#000" }}
            className={" answer-item-mobile"}
          >
            {"بعد الإطلاع على شرح البند الموجود أعلاه، هل توافق على هذا البند؟"}
          </Text>

          <div className={"flex gap-x-4 answer-item-mobile"}>
            <button
              // iconPosition="end"
              // title="نعم"
              className={twMerge(
                "min-w-[72px] h-10 rounded-[10px] bg-[#DFDFDF] flex items-center justify-center gap-x-2 font-effra text-[#92929D] focus:outline-none",
                answer === "yes" &&
                  "bg-[#FFF6E4] border-[1.5px] text-sm font-effra font-bold border-[#F5961E] text-[#F5961E] focus:outline-none"
              )}
              onClick={() => handleSendAnswer("yes")}
            >
              {"نعم"}
              <LikeIcon
                className={
                  answer === "yes" ? "fill-orange-400" : "fill-gray-400"
                }
              />
            </button>
            <button
              // iconPosition="end"
              // title="لا"
              className={twMerge(
                "min-w-[72px] h-10 rounded-[10px] bg-[#DFDFDF] flex items-center justify-center gap-x-2 font-effra text-[#92929D] focus:outline-none",
                answer === "no" &&
                  "bg-[#FFF6E4] border-[1.5px] text-sm font-effra font-bold border-[#F5961E] text-[#F5961E] focus:outline-none"
              )}
              onClick={() => handleSendAnswer("no")}
            >
              {"لا"}
              <DislikeIcon
                className={
                  answer === "no" ? "fill-orange-400" : "fill-gray-400"
                }
              />
            </button>
            {caliberIsQualitative && (
              <button
                // iconPosition="end"
                // title="لا ينطبق"
                className={twMerge(
                  "min-w-[72px] h-10 rounded-[10px] bg-[#DFDFDF] flex items-center justify-center gap-x-2 font-effra text-[#92929D] focus:outline-none",
                  answer === "not_applicable" &&
                    "bg-[#FFF6E4] border-[1.5px] text-sm font-effra font-bold border-[#F5961E] text-[#F5961E] focus:outline-none"
                )}
                onClick={() => handleSendAnswer("not_applicable")}
              >
                {"لا ينطبق"}
              </button>
            )}
          </div>
        </div>
        {isError ? (
          <div>
            <Text
              Tag={"div"}
              textType="roman"
              fontSize={"font-16"}
              style={{ color: "red" }}
              className={"leading-8 answer-error-mobile"}
            >
              * لم يتم الإجابة على هذا السؤال، يرجى اختيار الجواب المناسب
            </Text>
          </div>
        ) : null}
      </div>
    );
  }
};
