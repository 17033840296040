// @ts-check
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { twMerge } from "../../tw-merge";
import { Text } from "../typography";
import { usePassedSteps } from "../../../self-components/self-assessment-form-context";
// import { animated, useSpring } from '@react-spring/web';

// standard_categories level 1
// caliber_categories level 2
// calibers level 3

// activeStep = { index: 0, categoryIndex: 0, caliberIndex: 0 }
export const Stepper = (props) => {
  // ** props
  const { steps, activeStep, setActiveStep } = props;
  // const [{ y }, api] = useSpring(() => ({ y: 0, config: { duration: 500 } }));
  const innerActiveStepRef = useRef(activeStep.y);
  const passedSteps = usePassedSteps();
  useEffect(() => {
    innerActiveStepRef.current = activeStep.y;
  }, [activeStep]);
  // useEffect(() => {
  //   api.start({ from: { y: activeStep.y }, to: { y: 0 }, config: { duration: 350 } });
  // }, [activeStep, innerActiveStepRef.current]);

  const isAllowedToMove = useCallback((nextStep, nextCategory, nextCaliber) => {
    // check active step. if next click is < active step, then can move
    // if next step is > active step, then can't move
    // if next step === active step. then check if next category is < active category, then can move
    // if next category is > active category, then can not move
    // if next category === active category, then check if next caliber is < active caliber, then can move
    // if next caliber is > active caliber, then can not move
    // if next caliber === active caliber, then can not move
    if (nextStep > activeStep.index) return false;
    if (nextStep < activeStep.index) return true;
    if (nextStep === activeStep.index) {
      if (nextCategory > activeStep.categoryIndex) return false;
      if (nextCategory < activeStep.categoryIndex) return true;
      if (nextCategory === activeStep.categoryIndex) {
        if (nextCaliber > activeStep.caliberIndex) return false;
        if (nextCaliber < activeStep.caliberIndex) return true;
        if (nextCaliber === activeStep.caliberIndex) return false;
      }
    }
  }, [
    activeStep?.index,
    activeStep?.categoryIndex,
    activeStep?.caliberIndex,
  ]);

  const handleOnClick = (step, category, caliber) => {
    if (passedSteps?.[step]?.[category]?.[caliber]) {
      setActiveStep({ index: step, categoryIndex: category, caliberIndex: caliber });
      return;
    };
    if (isAllowedToMove(step, category, caliber)) {
      setActiveStep({
        index: step,
        categoryIndex: category,
        caliberIndex: caliber,
      });
    }
  };
  return (
    <div
      style={{ borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }}
      className="relative py-10 w-[316px] bg-ims-turquoise_blue overflow-auto"
    >
      <section className="text-gray-600 body-font pt-8">
        <div className="container flex flex-wrap" style={{ padding: 0 }}>
          <div className="flex flex-wrap w-full">
            <div className="w-full">
              {steps?.map((step, idx) => {
                const { caliber_categories, standard_category_name, is_disclaimer_caliber } =
                  step ?? {};

                const containerClss = twMerge(
                  "flex relative min-h-[96px] items-start",
                  activeStep?.index == idx && "bg-white/10 pb-8",
                  idx >= steps?.length ? "pb-6" : ""
                );

                const isLastItem = steps && idx === steps.length - 1;
                const itemClss = twMerge(
                  "h-full w-15 absolute inset-0 flex items-center justify-center mt-4",
                  idx >= steps.length && "flex-shrink-0 w-15 h-10",
                  idx >= steps.length - 1 && "mt-0",
                  isLastItem && "h-fit"
                );
                return (
                  <div
                    key={"standard_category_" + idx}
                    className={containerClss}
                    style={{
                      padding: "15px 23px 15px 23px",
                      paddingBottom: activeStep?.index != idx ? "64px" : "15px",
                    }}
                  >
                    <div className={itemClss} style={{ width: "60px" }}>
                      <div
                        className={twMerge(
                          // this condition maybe change later for if step completed or not
                          activeStep?.index > idx
                            ? "border-solid"
                            : "border-dashed",
                          "h-full w-1 border-r-[2px] ms-[18px]",
                          isLastItem && "border-none"
                        )}
                      />
                    </div>
                    <div
                      className={twMerge(
                        "flex-shrink-0 w-5 h-5 rounded-full bg-gray-300 inline-flex items-center justify-center text-white relative z-10",
                        activeStep?.index < idx && "w-4 h-4 ",
                      )}
                      style={{
                        height: idx > activeStep?.index ? "16px" : "30px",
                        width: idx > activeStep?.index ? "16px" : "30px",
                        marginInlineStart: idx > activeStep?.index ? "7px" : "0px",
                        marginTop: idx > activeStep?.index ? "6px" : "0px",
                      }}
                    >
                      {activeStep.index > idx && (
                        <img src="img/check-icon.svg" />
                        // <CheckIcon className="w-6 h-5 font-bold fill-ims-deep_sea_blue" />
                      )}
                      {activeStep.index === idx && (
                        <div
                          className="w-2 h-2 rounded-full bg-[#13686C]"
                          style={{ height: "12px", width: "12px" }}
                        />
                      )}
                    </div>
                    <div
                      className={`flex-grow z-[99999999] cursor-pointer pr-4 ${activeStep?.index == idx ? "" : ""
                        }`}
                      onClick={() => handleOnClick(idx, 0, 0)}
                    >
                      <div

                        className={"w-full"}
                      >
                        <h2 className="font-medium font-mol text-start title-font text-lg text-gray-200 mb-[8px] tracking-wider">
                          {standard_category_name}
                        </h2>
                      </div>

                      {!is_disclaimer_caliber && (
                        caliber_categories?.map((caliberCategory, caliberCategoryIdx) => {
                          return (
                            <div
                              key={"caliber_category_" + caliberCategoryIdx}
                              className={`mt-[8px] text-start flex flex-col ${activeStep?.index == idx ? "" : ""}`}
                              style={{
                                display: activeStep?.index == idx ? "block" : "none",
                              }}
                            >
                              <div
                                onClick={() => handleOnClick(idx, caliberCategoryIdx, 0)}
                                className={"w-full"}
                              >
                                <Text className="text-white text-start font-mol">
                                  {" "}
                                  - {caliberCategory?.caliber_category_name}
                                </Text>
                              </div>
                              {caliberCategory?.calibers?.map((caliber, caliberIdx) => {
                                const clss = twMerge(
                                  "w-[80%] bg-transparent rounded-lg px-4 h-10 cursor-pointer relative"
                                );

                                const isSelected =
                                  activeStep?.index == idx &&
                                  activeStep?.categoryIndex == caliberCategoryIdx &&
                                  activeStep?.caliberIndex == caliberIdx;

                                const txtClss = twMerge(
                                  isSelected
                                    ? "text-ims-deep_sea_blue text-start font-mol mb-0 pt-2"
                                    : "text-white text-start font-mol"
                                );
                                return (
                                  <div
                                    key={"caliber_index_" + caliberIdx}
                                    className="relative h-10 transition-all"
                                  >
                                    {isSelected && (
                                      <div
                                        style={{
                                          height: 35,
                                          position: "absolute",
                                          width: "80%",
                                          left: 0,
                                          right: 0,
                                          backgroundColor: "rgba(255,255,255,0.8)",
                                          borderRadius: 5,
                                          padding: "5px 24px",
                                        }}
                                      />
                                    )}
                                    <div
                                      onClick={() => handleOnClick(idx, caliberCategoryIdx, caliberIdx)}
                                      className={clss}
                                    >
                                      <Text className={txtClss}>
                                        {"البند"} {caliberCategoryIdx + 1}.{caliberIdx + 1}
                                      </Text>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })
                      )}

                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
