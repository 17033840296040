import React , { useRef , useState , useEffect } from "react";
import "./contentTable.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";



export const ContentTable = ({
    standardCategories,
    mainContentRef,
  }) => {


    const [activeSection, setActiveSection] = useState({
      standardCategoryIndex: null,
      caliberCategoryIndex: null
    });

    const headerOffset = 150;

    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY + headerOffset;
        const viewportHeight = window.innerHeight;

        const visibleSectionIndex = standardCategories.findIndex((standardCategory, index) => {
          const caliberCategoryIndex = standardCategory.caliber_categories.findIndex((caliberCategory, subIndex) => {
            const targetElement = mainContentRef.current.querySelector(
              `#caliber-category-${index}-${subIndex}`
            );

            if (!targetElement) return false;

            const elementRect = targetElement.getBoundingClientRect();
            const elementTop = elementRect.top + window.scrollY;
            const elementBottom = elementRect.bottom + window.scrollY;

            return elementBottom >= scrollPosition && elementTop <= scrollPosition + viewportHeight;
          });

          setActiveSection((prevState) => ({
            ...prevState,
            caliberCategoryIndex: caliberCategoryIndex,
          }));

          return caliberCategoryIndex !== -1;
        });

        if (visibleSectionIndex !== -1) {
          setActiveSection((prevState) => ({
            ...prevState,
            standardCategoryIndex: visibleSectionIndex,
          }));
        }
      };

      window.addEventListener('scroll', handleScroll);
      handleScroll();
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [standardCategories, mainContentRef, headerOffset]);



   return(
    <div className="contentSection">
    <div className="content-header">
          <header className="standard-category-header">
              <Text
                textType="h7"
                isBold
                className="standard-category-header__name"
              >
                جدول المحتوى
              </Text>
            </header>
     </div>
     <div className="content-list">
     <ul >
      {standardCategories.map((standardCategory, index) => (
        <li key={index}>
          <a   onClick={() => {

              const targetElement = mainContentRef.current.querySelector(
                `#standard-category-${index}`
                 );

              const elementPosition = targetElement.getBoundingClientRect().top;
              const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

              window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
              });
                 }}
            >
            <Text
                textType="h7"
                isBold
                className="standardCategory"
              >
               {standardCategory.name}
              </Text>
          </a>
          <ul>
            {standardCategory.caliber_categories
              .filter(caliberCategory => !caliberCategory.is_disclaimer_caliber)
              .map((caliberCategory, caliberCategoryIndex) => (
                <li key={caliberCategoryIndex}>
                  <a
                    onClick={() => {
                      const targetElement = mainContentRef.current.querySelector(
                        `#caliber-category-${index}-${caliberCategoryIndex}`
                      );

                      const elementPosition = targetElement.getBoundingClientRect().top;
                      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                      window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Text
                      textType="h9"
                      isBold
                      className={`caliberCategory ${activeSection.standardCategoryIndex === index && activeSection.caliberCategoryIndex === caliberCategoryIndex ? 'activeContent' : ''}`}
                    >
                      {caliberCategory.name}
                    </Text>
                  </a>
                </li>
              ))}
          </ul>
        </li>
      ))}
    </ul>
    </div>
  </div>
   );
  };

export default ContentTable;
