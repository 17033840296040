import request from "../../../../../../common/request";
import { ANSWER_YES } from "../../../../../../common/constants/caliberAnswers";

export const sendCaliberAnswer = async (answer, data, caliberID, setCalibers, establishmentProfileId, period, isReturned, status) => {
  const periodId = period.id;
  const assessmentVersion = period.assessment_version;
  const periodsAssessmentId = period.period_assessment_id;
  const assessmentId = period.assessment.id;
  const payload = {
    period_id: periodId,
    assessment_id: assessmentId,
    assessment_caliber: caliberID,
    period_assessment_id: periodsAssessmentId,
    answer,
    assessment_version: assessmentVersion,
    note: answer === ANSWER_YES ? "" : data.note,
    violation_number: answer === ANSWER_YES ? null : data.violation_number,
    status,
  };

  const baseUrl = `/v1/establishments/${establishmentProfileId}/`;
  const url = isReturned ? baseUrl + "returned_assessment" : baseUrl + "assessments";
  try {
    const res = await request(url, "PATCH", { submission: payload });
    if (res?.status === 200) {
      if (isReturned) {
        setCalibers({
          [caliberID]: { ...data, new_answer: answer },
        });
      } else {
        setCalibers({
          [caliberID]: { ...data, answer },
        });
      }
      return true;
    }
  } catch (error) {
    // Handle error
    return false;
  }
  return false;
};
